<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-15 15:30:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-22 09:31:18
-->
<template>
  <el-table  :data="reconciliationList" border  :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="transportationName" label="运输企业名称" ></el-table-column>
    <el-table-column prop="unifiedSocialCode" label="统一社会代码" ></el-table-column>
    <el-table-column prop="transportationPhone" label="联系电话" ></el-table-column>
    <el-table-column label="创建时间" prop="createDate" width="170px">
          <template slot-scope="scope">
            {{scope.row.createDate }}
          </template>
    </el-table-column>
    <el-table-column label="操作" width="200px">
      <template slot-scope="scope">
        <transport-edit :role-id="scope.row" @plan-list="updateRoleList"/>
        <transport-remove :row-id="scope.row.transportationId" :enabled="scope.row.enabled" @plan-list="updateRoleList"/>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import TransportEdit from './TransportEdit';
import TransportRemove from './TransportRemove.vue';

  export default {
    name: "TransportTable",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
        TransportEdit,
        TransportRemove
        
    },
    methods: {

      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      }
    }
  }
</script>

<style scoped>

</style>
