<!--
 * @Description:
 * @Author: 
 * @Date: 2023-04-04 10:07:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-16 14:39:44
-->
<template>
  <el-button type="text"  @click="removeRoleById(rowId)">{{ enabled==1?'关闭':'开启' }}</el-button>
</template>

<script>
  import {clearTransportRequest} from 'network/customer'

  export default {
    name: "TransportRemove",
    props: {
      rowId: {
        type: Number,
        required: true
      },
      enabled: {
        type: Number,
        required: true
      }
    },
    methods: {
      // 根据ID删除用户
      removeRoleById(row) {
        this.$confirm(`此操作将${this.enabled==1?'关闭':'开启'}该企业, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          
            clearTransportRequest({
              transportationId: this.rowId,
              enabled: this.enabled==1?2:1
            }).then(res => {
            let result = res.data;
            if (result.code !== 200) {
              return this.alertMessage(result.msg, 'error');
            }
            this.alertMessage(this.enabled==1?'关闭成功':'开启成功', 'success');
            this.$emit('plan-list');
          });
        }).catch(() => {
          this.alertMessage('已取消','info');
        });
      }
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
