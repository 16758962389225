<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-06 09:46:37
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-04 10:19:46
-->
<template>
  <div class="editDialog">
    <el-button type="primary" @click="editDialogVisible=true" icon="el-icon-plus">新建</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="新建运输企业"
            :visible.sync="editDialogVisible"
            :before-close="handleClose"
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="企业名称" prop="transportationName">
          <el-input v-model="ruleForm.transportationName" placeholder="请输入企业名称" ></el-input>
        </el-form-item>
        <el-form-item label="统一社会代码" prop="unifiedSocialCode">
          <el-input v-model="ruleForm.unifiedSocialCode" placeholder="请输入统一社会代码" ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="transportationPhone">
          <el-input v-model="ruleForm.transportationPhone" placeholder="请输入联系电话" ></el-input>
        </el-form-item>
       
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear()">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addTransportRequest } from 'network/customer'

  export default {
    name: "TransportEdit",
    props: {
    },
    data() {
      return {
        editDialogVisible: false,
        ruleForm: {
          transportationName: '',
          unifiedSocialCode: '',
          transportationPhone: ''
        },
        rules: {
          transportationName: [
            {required: true, message: '请输入企业名称', trigger: 'blur'},
          ],
          unifiedSocialCode: [
            {required: true, message: '请输入统一社会信用代码', trigger: 'blur'},
            { min: 3, max: 18, message: '请输入正确的统一社会信用代码', trigger: 'blur' }
          ],
          transportationPhone: [
            {required: true, message: '请输入管理员电话', trigger: 'blur'},
            { min: 7, max: 11, message: '请输入正确的电话格式', trigger: 'blur' }
          ],
        },
      }
    },
    methods: {

      // 监听编辑角色的弹框的关闭事件
      handleClose() {
        this.$refs.ruleForm.resetFields();
      },

      clear(){
        this.$refs.ruleForm.resetFields()
        this.editDialogVisible=false
      },

      // 点击编辑角色弹框的“确认”按钮提交
      editRole() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            addTransportRequest(
              {
                ...this.ruleForm,
                tardId: localStorage.getItem('enterpriseId') 
              }
            ).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage('添加失败', 'error');
                }
                this.alertMessage('添加成功', 'success');
                // 隐藏弹窗
                this.editDialogVisible=false
                this.$refs.ruleForm.resetFields();
                this.$emit('plan-list');
              })
          } else {
            return false;
          }
        })
      }
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
