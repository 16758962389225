<template>
  <div style="height: 100%;">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>客户管理</template>
        <template v-slot:secondMenu>运输企业管理</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        运输企业管理
      </div>
      <div class="radioBox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="运输企业名称">
          <el-input autosize v-model="formInline.transportationName" placeholder="请输入运输企业名称" @input="onSubmit"></el-input>
        </el-form-item>
      </el-form>
      </div>
      <div class="buttonBox">
        <transport-add @plan-list='updateRoleList'/>
      </div>
      <!-- table表格区域 -->
      <transport-table :reconciliation-list="goodsList" @plan-list='updateRoleList' />
      <div style="float: right;">
        <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import BreadcrumbNav from "../../common/BreadcrumbNav";
  import { getTransportRequest } from "network/customer";
import TransportTable from './components/TransportTable.vue';
import TransportAdd from './components/TransportAdd.vue';


  export default {
    name: "List",
    components: {
      BreadcrumbNav,
        TransportTable,
        TransportAdd,
    },
    data() {
      return {
         radio1: '0',
        queryInfo: {
          query: "",
          pageNum: 1,
          pageSize: 10
        },
        formInline: {
          transportationName: '',
          region: ''
        },
        goodsList: [ ],
        total: 0,
      }
    },
    created() {
      this.getGoodsList();
    },
    methods: {
      onSubmit() {
        this.getGoodsList()
      },
      getGoodsList() {
        getTransportRequest({
            ...this.queryInfo,
            transportationName:this.formInline.transportationName
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取商品数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage;
        this.getGoodsList();
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        this.getGoodsList()
      }
    }
  }
</script>

<style scoped>

.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
}
.buttonBox{
  display: flex;
}
</style>
